import { axiosDefaultInstance } from '@/http/default'

import type { DeliveryNote, DeliveryNoteImages, DeliveryNoteRequest } from '@/model/DeliveryNote'
import { useProfileStore } from '@/store/profile.module'
import type { AxiosInstance } from 'axios'
import handleError from './util/ErrorHandler'
import type { ServiceError } from './util/ServiceError'
import type { ChartType, Statistics } from '@/model/Statistics'

class DeliveryNoteService {
  private axiosInstance: AxiosInstance
  private storeProfile: any

  constructor() {
    this.axiosInstance = axiosDefaultInstance
    // this.storeProfile = useProfileStore()
  }

  async findAll(
    startDate?: string,
    endDate?: string,
    employeeIds?: string[],
    vehicleIds?: string[],
    billed?: boolean,
    uncompleted?: boolean
  ): Promise<DeliveryNote[] | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []

    if (startDate) {
      params.push(['startDate', startDate])
    }

    if (endDate) {
      params.push(['endDate', endDate])
    }

    if (employeeIds !== undefined) {
      employeeIds.forEach((id) => params.push(['employeeId', id]))
    }

    if (vehicleIds !== undefined) {
      vehicleIds.forEach((id) => params.push(['vehicleId', id]))
    }

    if (billed !== undefined) {
      params.push(['billed', String(billed)])
    }

    if (uncompleted !== undefined) {
      params.push(['uncompleted', String(uncompleted)])
    }

    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<DeliveryNote[]>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findById(id: string): Promise<DeliveryNote | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<DeliveryNote>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note/${id}`
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async getDeliveryNoteImages(
    id: string
  ): Promise<DeliveryNoteImages | ServiceError> {
    const companyId: string = this.storeProfile.selectedCompany.id;

    try {
      const networkResponse = await this.axiosInstance.get<DeliveryNoteImages>(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/company/${companyId}/delivery-note/mine/${id}/images`
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async downloadPdfById(id: string): Promise<Blob | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Blob>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note/${id}/pdf`,
        {
          responseType: 'blob'
        }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async createDeliveryNote(
    deliveryNote: DeliveryNoteRequest
  ): Promise<DeliveryNote | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.post<DeliveryNote>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note`,
        deliveryNote
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async updateDeliveryNote(
    deliveryNote: DeliveryNoteRequest
  ): Promise<DeliveryNote | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.put<DeliveryNote>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note/${deliveryNote.id}`,
        deliveryNote
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteDeliveryNote(id: string): Promise<DeliveryNote | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.delete<DeliveryNote>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note/${id}`
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findStatistics(
    statisticFilters: ChartType[],
    startDate: string,
    endDate: string,
    employeeIds: string[],
    vehicleIds: string[],
    billed?: boolean,
    uncompleted?: boolean
  ): Promise<Statistics | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []

    if (employeeIds !== undefined) {
      employeeIds.forEach((id) => params.push(['employeeId', id]))
    }

    if (vehicleIds !== undefined) {
      vehicleIds.forEach((id) => params.push(['vehicleId', id]))
    }

    if (billed !== undefined) {
      params.push(['billed', String(billed)])
    }

    if (uncompleted !== undefined) {
      params.push(['uncompleted', String(uncompleted)])
    }

    if (statisticFilters) {
      statisticFilters.forEach((f) => params.push(['statisticsFilters', String(f)]))
    }

    params.push(['startDate', startDate])
    params.push(['endDate', endDate])

    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<any>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/delivery-note/statistics`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }
}

export const deliveryNoteService = new DeliveryNoteService()
