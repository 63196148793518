<script setup lang="ts">
import { authService } from '@/services/AuthService'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const router = useRouter()
const { t } = useI18n()

const items = ref([
  {
    label: t('menu.home'),
    // icon: 'pi pi-home',
    command: () => {
      router.push('/')
    }
  },
  {
    label: t('menu.resources'),
    // icon: 'pi pi-search',
    items: [
      {
        label: t('customers.title'),
        icon: 'pi pi-users',
        command: () => {
          router.push('/customers')
        }
      },
      {
        label: t('vehicles.title'),
        icon: 'pi pi-truck',
        command: () => {
          router.push('/vehicles')
        }
      },
      {
        label: t('places.title'),
        icon: 'pi pi-map-marker',
        command: () => {
          router.push('/places')
        }
      },
      {
        label: t('refuels.title'),
        icon: 'pi pi-gauge',
        command: () => {
          router.push('/refuels')
        }
      },
      {
        label: t('prices.title'),
        icon: 'pi pi-euro',
        command: () => {
          router.push('/prices')
        }
      },
      {
        label: t('employees.title'),
        icon: 'pi pi-user',
        command: () => {
          router.push('/employees')
        }
      }
    ]
  },
  {
    label: t('menu.billing'),
    // icon: 'pi pi-search',
    items: [
      {
        label: t('deliveryNotes.title'),
        icon: 'pi pi-file',
        command: () => {
          router.push('/deliveryNotes')
        }
      },
      {
        label: t('bills.title'),
        icon: 'pi pi-money-bill',
        command: () => {
          router.push('/bills')
        }
      }
    ]
  },
  {
    label: t('menu.config'),
    // icon: 'pi pi-home',
    command: () => {
      router.push('/profile/deliveryNotesConfig')
    }
  }
  // {
  //   label: 'About',
  //   icon: 'pi pi-star',
  //   command: () => {
  //     router.push('/about')
  //   }
  // }
])

const signOut = async () => {
  await authService.logout()
  router.replace('login')
}
</script>

<template>
  <Menubar :model="items">
    <template #start>
      <Image
        class="hidden lg:block"
        src="/logo.png"
        alt="logo"
        height="30"
        style="cursor: pointer"
        @click="router.push('/')"
      />
    </template>
    <template #item="{ item, props, hasSubmenu, root }">
      <a class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" />
        <span
          v-if="item.shortcut"
          class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
          >{{ item.shortcut }}</span
        >
        <i
          v-if="hasSubmenu"
          :class="[
            'pi pi-angle-down',
            { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }
          ]"
        ></i>
      </a>
    </template>
    <template #end>
      <div class="flex align-items-center gap-2">
        <Button
          class="signOutButton"
          :label="$t('login.signOut')"
          icon="pi pi-sign-out"
          iconPos="right"
          link
          @click="signOut"
        />
      </div>
    </template>
  </Menubar>
</template>

<style scoped>
.p-menubar {
  border: none;
}

.signOutButton {
  color: black;
}
</style>
