import moment from 'moment'
import i18n from '@/i18n'

const dateHelper = {
  /**
   * Returns current date with 00:00:00 hours
   * @returns the date of current day with 00:00:00 hours
   */
  startOfToday() {
    return moment().startOf('day').format(this.getIsoFormat())
  },
  /**
   * Returns current date with 23:59:59 hours
   * @returns the date of current day with 23:59:59 hours
   */
  endOfToday() {
    return moment().endOf('day').format(this.getIsoFormat())
  },
  /**
   * Given a date it returns the same day with 00:00:00 hours
   * @param date
   * @returns the date of the same day with 00:00:00 hours
   */
  startOfCertainDay(date: string) {
    return moment(date).startOf('day').format(this.getIsoFormat())
  },
  /**
   * Given a date it returns the same day with 23:59:59 hours
   * @param date
   * @returns the date of the same day with 23:59:59 hours
   */
  endOfCertainDay(date: string) {
    return moment(date).endOf('day').format(this.getIsoFormat())
  },
  /**
   * Given a date it returns the elapsed days between the given date and today
   * @param date start day to calculate elapsed days from
   * @returns the elapsed days between the given date and today
   */
  elapsedDaysSinceDate(dateUTC: string) {
    const today = new Date()
    const start = new Date(
      moment(dateHelper.utcToUserTimezone(dateUTC)).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
    )
    const elapsedTimeMillis = today.getTime() - start.getTime()
    return Math.ceil(elapsedTimeMillis / (1000 * 60 * 60 * 24))
  },

  /**
   * Given a date in UTC it returns the elapsed time between the given date and current time in milliseconds
   * @param date date in UTC to calculate elapsed time from
   * @returns the elapsed time between the given date and current time in milliseconds
   */
  elapsedMillisecondsSinceDateUtc(dateUTC: string) {
    const now = new Date()
    const date = new Date(dateHelper.utcToUserTimezone(dateUTC))
    return now.getTime() - date.getTime()
  },
  /**
   * Given a date in UTC it returns the elapsed time between the given date and current time in milliseconds
   * @param date date in UTC to calculate elapsed time from
   * @returns the elapsed time between the given date and current time in milliseconds
   */
  elapsedMillisecondsSinceDateLocal(dateLocal: string) {
    const now = new Date()
    const date = new Date(dateLocal)
    return now.getTime() - date.getTime()
  },
  /**
   * Given a given a date in locale timezone it returns the same date in UTC ISO string format
   * @param date date in locale timezone
   * @returns the date in UTC ISO string format
   */
  userTimezoneDateToUTC(date: Date) {
    return date.toISOString()
  },

  /**
   * Given a given a date in locale ISO string it returns the same date in UTC ISO string format
   * @param date date in ISO string
   * @returns the date in UTC ISO string format
   */
  userTimezoneToUTC(dateString: string) {
    return moment(dateString).utc().format('YYYY-MM-DDTHH:mm:ss')
  },

  /**
   * Given a UTC date in ISO string it returns the same date in user local timezone in ISO string format
   * @param date UTC date in ISO string
   * @returns the date in user local timezone in ISO string format
   */
  utcToUserTimezone(dateStringUTC: string) {
    return moment.utc(dateStringUTC).local().format('YYYY-MM-DDTHH:mm:ss')
  },
  /**
   * Given a timeStamp it returns the relative time from now in a user timezone language string
   * @param date
   * @returns the relative time from now in a user timezone language string
   */
  momentFromNow(timeStamp: number) {
    moment.locale(i18n.global.locale.value)
    return moment(timeStamp).fromNow()
  },

  /**
   * Given a timeStamp it returns the relative time from now in a user timezone language string
   * @param date
   * @returns the relative time from now in a user timezone language string
   */
  momentFromDate(timeStamp: number, fromTimestamp: number) {
    moment.locale(i18n.global.locale.value)
    return moment(timeStamp).from(fromTimestamp)
  },

  /**
   * Given a date it returns the date with format dd/mm/yyyy
   * @param date
   * @returns the date with format dd/mm/yyyy
   */
  formatDate(date: string | undefined): string {
    if (!date) {
      return ''
    }
    const newDate = new Date(date)
    moment.locale(i18n.global.locale.value || 'es')
    return `${moment(newDate).format('DD/MM/YYYY')}`
  },

  /**
   * Given a date it returns the date with moment format 'MMMM D, h:mm a'
   * @param date
   * @returns the date with format 'MMMM D, h:mm a'
   */
  formatDateWithHour(date: string): string {
    moment.locale(i18n.global.locale.value || 'es')
    return moment(date).format("DD/MM/YYYY HH:mm");
  },

  /**
   * Given a date it returns the hour with moment format 'HH:mm' (24h format)
   * @param date
   * @returns the hour with moment format 'HH:mm' (24h format)
   */
  formatDateOnlyHour24h(date: string): string {
    moment.locale(i18n.global.locale.value)
    return moment(date).format('HH:mm')
  },

  /**
   * Given a date it returns the date with moment format 'MMMM D, h:mm a'
   * @param date
   * @returns the date with format 'MMMM D, h:mm a'
   */
  formatLongDate(date: string): string {
    moment.locale(i18n.global.locale.value)
    return moment(date).format('dddd, L - H:mm\\h')
  },

  /**
   * Returns the format of the date depending on the locale with IonDateTime format
   * @returns The format of the date
   */
  getDateFormatForIonDateTime() {
    if (i18n.global.locale.value == 'es') {
      return 'DD-MM-YYYY'
    } else {
      return 'MM-DD-YYYY'
    }
  },

  /**
   * Given a date string it returns the number value of the date according to universal value
   * @param date string of the date or undefined
   * @returns the number value of the date according to universal value
   */
  getTimeFromDateString(date: string | undefined): number {
    if (!date) {
      return 0
    }
    return new Date(date).getTime()
  },

  /**
   * Returns the ISO format
   * @returns The ISO format
   */
  getIsoFormat() {
    return 'YYYY-MM-DDTHH:mm:ss'
  }
}

export default dateHelper
