import 'primevue/resources/themes/aura-light-green/theme.css'
import './assets/main.css'

import { createPinia } from 'pinia'
import { createApp } from 'vue'

/* internationalization*/
import i18n from './i18n'

/* Sentry */
import * as Sentry from '@sentry/vue'

/* primevue*/
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import AutoComplete from 'primevue/autocomplete'
import Avatar from 'primevue/avatar'
import Badge from 'primevue/badge'
import Breadcrumb from 'primevue/breadcrumb'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Column from 'primevue/column'
import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Fieldset from 'primevue/fieldset'
import FloatLabel from 'primevue/floatlabel'
import Image from 'primevue/image'
import InlineMessage from 'primevue/inlinemessage'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Listbox from 'primevue/listbox'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OverlayPanel from 'primevue/overlaypanel'
import Password from 'primevue/password'
import RadioButton from 'primevue/radiobutton'
import Steps from 'primevue/steps'
import TabMenu from 'primevue/tabmenu'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import Toolbar from 'primevue/toolbar'
import TriStateCheckbox from 'primevue/tristatecheckbox'

import { FilterService } from 'primevue/api'

import moment from 'moment'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import ToastService from 'primevue/toastservice'
import App from './App.vue'
import type { Bill } from './model/Bill'
import type Customer from './model/Customer'
import { type Payload } from './model/DeliveryNote'
import type Employee from './model/Employee'
import type Vehicle from './model/Vehicle'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)

// i18n
app.use(i18n)

// Custom filters
FilterService.register('employeeFilter', (value: Employee, filter): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return (
    value.name.toString().toLowerCase().includes(filter.toString().toLowerCase()) ||
    value.surname.toString().toLowerCase().includes(filter.toString().toLowerCase())
  )
})

FilterService.register('vehiclesFilter', (value: Vehicle[], filter): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return (
    value.filter((v) => {
      return v.name.toString().toLowerCase().includes(filter.toString().toLowerCase())
    }).length > 0
  )
})

FilterService.register('vehiclesFilterMultiple', (value: Vehicle[], ids: String[]): boolean => {
  debugger
  if (ids === undefined || ids === null || ids.length == 0) {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return (
    value.filter((v) => {
      return ids.includes(v.id)
    }).length > 0
  )
})

FilterService.register('employeeFilterMultiple', (value: Employee, ids: String[]): boolean => {
  if (ids === undefined || ids === null || ids.length == 0) {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return ids.includes(value.id)
})

FilterService.register('vehicleFilterMultiple', (value: Vehicle, ids: String[]): boolean => {
  if (ids === undefined || ids === null || ids.length == 0) {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return ids.includes(value.id)
})

FilterService.register('customerFilterMultiple', (value: Customer, ids: String[]): boolean => {
  if (ids === undefined || ids === null || ids.length == 0) {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return ids.includes(value.id)
})

FilterService.register('dateFilter', (value, filter): boolean => {
  if (filter === undefined || filter === null || filter.length === 0) {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  // if (filter.length === 1) {
  //   return moment(value).isSameOrAfter(moment(filter[0]))
  // }

  if (filter.length === 2 && filter[1] !== undefined && filter[1] !== null) {
    return (
      moment(value).isSameOrAfter(moment(filter[0]).startOf('day')) &&
      moment(value).isSameOrBefore(moment(filter[1]).endOf('day'))
    )
  }

  return true
})

FilterService.register('payloadsFilter', (value: Payload[], filter): boolean => {
  if (filter === undefined || filter === null || filter.trim() === '') {
    return true
  }

  if (value === undefined || value === null) {
    return false
  }

  return (
    value.filter((v) => {
      return v.payload.toString().toLowerCase().includes(filter.toString().toLowerCase())
    }).length > 0
  )
})

FilterService.register(
  'billedFilter',
  (value: Bill | undefined, filter: boolean | undefined): boolean => {
    if (filter === undefined || filter === null) {
      return true
    }

    if (filter === true) {
      return value?.id !== undefined
    } else {
      return value?.id === undefined
    }
  }
)

if (i18n.global.locale.value == 'es') {
  const messages = await import('./locales/es.json')
  app.use(PrimeVue, { ripple: true, locale: messages.primevue }) // Primevuew
} else {
  const messages = await import('./locales/en.json')
  app.use(PrimeVue, { ripple: true, locale: messages.primevue }) // Primevuew
}

// Primevue components
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar)
app.component('Badge', Badge)
app.component('Breadcrumb', Breadcrumb)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Card', Card)
app.component('Checkbox', Checkbox)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('Fieldset', Fieldset)
app.component('FloatLabel', FloatLabel)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
app.component('InlineMessage', InlineMessage)
app.component('Listbox', Listbox)
app.component('Menu', Menu)
app.component('Menubar', Menubar)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('OverlayPanel', OverlayPanel)
app.component('Password', Password)
app.component('RadioButton', RadioButton)
app.component('Steps', Steps)
app.component('TabMenu', TabMenu)
app.component('TabPanel', TabPanel)
app.component('TabView', TabView)
app.component('Textarea', Textarea)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('Image', Image), app.component('TriStateCheckbox', TriStateCheckbox)

app.use(ToastService)

const isDevMode = import.meta.env.DEV

Sentry.init({
  app,
  dsn: 'https://01ec735ffbc9e9c605e88b4c564cccdf@o4507351225204736.ingest.de.sentry.io/4507351264657488',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: isDevMode // Disable on dev mode
})

app.mount('#app')
