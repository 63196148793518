<template>
  <Toast />
  <div class="summary flex flex-column align-items-start gap-3 mb-5">
    <div class="flex flex-column align-items-start gap-3 m-3">
      <h1>{{ selectedCompany?.name }}</h1>
      <p>{{ deliveryNotes.length }} albaranes sin facturar</p>
    </div>
  </div>
  <div class="filters">
    <Fieldset legend="Filtros" :toggleable="true" class="mt-3" :collapsed="false">
      <div class="pt-3 pb-3 grid">
        <div class="col-6 md:col-6 lg:col-3">
          <Dropdown
            v-model="monthSelected"
            :options="monthOptions"
            optionLabel="label"
            placeholder="Select a City"
            class="w-full md:w-14rem"
            @change="onFilterChange()"
          />
        </div>
      </div>
      <div class="pb-3 grid row-gap-3">
        <div class="col-6 md:col-6 lg:col-3">
          <FloatLabel>
            <MultiSelect
              id="vehicles-filter"
              filter
              v-model="vehiclesFilter"
              :options="vehicles"
              optionLabel="plate"
              placeholder="Vehículo"
              selectedItemsLabel="{0} vehículos seleccionados"
              :maxSelectedLabels="2"
              class="w-full"
              @change="onFilterChange()"
            />
            <label for="vehicles-filter">Vehículo</label>
          </FloatLabel>
        </div>
        <div class="col-6 md:col-6 lg:col-3">
          <FloatLabel>
            <MultiSelect
              id="employees-filter"
              filter
              v-model="employeeFilter"
              :options="employees"
              optionLabel="name"
              placeholder="Empleado"
              selectedItemsLabel="{0} empleados seleccionados"
              :maxSelectedLabels="2"
              class="w-full"
              @change="onFilterChange()"
            />
            <label for="employees-filter">Empleado</label>
          </FloatLabel>
        </div>
      </div>
    </Fieldset>
  </div>
  <div class="dashboard-1-line grid mt-3 mb-6">
    <div class="col-12 md:col-10 delivery-note-per-day-chart">
      <Card>
        <template #content>
          <div class="c-chart__container">
            <canvas id="delivery-notes-chart"></canvas>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12 md:col-2 delivery-note-counter-div h-100">
      <Card class="delivery-note-counter-card">
        <template #content>
          <div class="delivery-note-counter-title"><p>Nº de albaranes</p></div>
          <div class="delivery-note-counter-chart">
            <p>{{ deliveryNotesCounter }}</p>
          </div>
        </template>
      </Card>
    </div>
  </div>
  <div v-if="!loading && isDeliveryNotesEmpty" class="flex justify-content-center mt-5">
    <EmptyState
      :heading="$t('home.emptyDeliveryNotesTitle')"
      :text="$t('home.emptyDeliveryNotesDescription')"
    ></EmptyState>
  </div>
  <div class="flex justify-content-center data-container" v-if="!loading && !isDeliveryNotesEmpty">
    <DataTable
      :value="deliveryNotes"
      paginator
      :rows="50"
      :rowsPerPageOptions="[50, 100]"
      v-model:filters="filters"
      filterDisplay="menu"
    >
      <Column
        field="number"
        :header="$t('deliveryNotes.number')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.number') })"
            @input="filterCallback"
          /> </template
      ></Column>
      <Column
        field="date"
        :header="$t('deliveryNotes.date')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #body="slotProps">{{
          dateHelper.formatDate(dateHelper.utcToUserTimezone(slotProps.data.date))
        }}</template>
        <template #filter="{ filterModel, filterCallback }">
          <Calendar
            v-model="filterModel.value"
            selectionMode="range"
            dateFormat="dd/mm/yy"
            placeholder="dd/mm/yyyy"
            mask="99/99/9999"
            @date-select="filterCallback"
            @blur="filterCallback"
          />
        </template>
      </Column>
      <Column
        field="customer.name"
        :header="$t('deliveryNotes.customer')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.customer') })"
            @input="filterCallback"
          /> </template
      ></Column>
      <Column
        field="employee"
        :header="$t('deliveryNotes.employee')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.employee') })"
            @input="filterCallback"
          />
        </template>
        <template #body="slotProps">{{
          slotProps.data.employee.name + ' ' + slotProps.data.employee.surname
        }}</template>
      </Column>
      <Column
        field="vehicles"
        :header="$t('deliveryNotes.vehicles')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #body="slotProps">{{
          (slotProps.data.vehicles as Vehicle[]).map((v) => v.plate).join(', ')
        }}</template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.vehicles') })"
            @input="filterCallback"
          />
        </template>
      </Column>
      <Column
        field="origin.name"
        :header="$t('deliveryNotes.origin')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.origin') })"
            @input="filterCallback"
          />
        </template>
      </Column>
      <Column
        field="destination.name"
        :header="$t('deliveryNotes.destination')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.destination') })"
            @input="filterCallback"
          /> </template
      ></Column>
      <Column
        field="payloads"
        :header="$t('deliveryNotes.payloads')"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        :showClearButton="true"
        :show-apply-button="false"
      >
        <template #body="slotProps">
          <span v-html="formatPayloads(slotProps.data.payloads)"></span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="$t('common.searchByName', { name: $t('deliveryNotes.payloads') })"
            @input="filterCallback"
          />
        </template>
      </Column>
      <Column field="signed" :header="$t('deliveryNotes.signed')" dataType="boolean">
        <template #body="slotProps">{{ getSignedLabel(slotProps.data) }}</template>
      </Column>
      <Column field="billed" :header="$t('deliveryNotes.billed')" dataType="boolean">
        <template #body="slotProps">{{
          slotProps.data.billed === true ? $t('common.yes') : $t('common.no')
        }}</template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button icon="pi pi-eye" @click="onDeliveryNoteView(slotProps.data)" text rounded />
          <Button icon="pi pi-file-pdf" @click="onDownloadPdf(slotProps.data)" text rounded />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import EmptyState from '@/components/EmptyState.vue'
import type Customer from '@/model/Customer'
import { DeliveryNoteType, type DeliveryNote, type Payload } from '@/model/DeliveryNote'
import type Employee from '@/model/Employee'
import type Place from '@/model/Place'
import { ChartType, type Serie, type Statistics } from '@/model/Statistics'
import type Vehicle from '@/model/Vehicle'
import router from '@/router'
import { customerService } from '@/services/CustomerService'
import { deliveryNoteService } from '@/services/DeliveryNoteService'
import { employeeService } from '@/services/EmployeeService'
import { placeService } from '@/services/PlaceService'
import { vehicleService } from '@/services/VehicleService'
import { ServiceError } from '@/services/util/ServiceError'
import { useProfileStore } from '@/store/profile.module'
import dateHelper from '@/utils/dateHelper'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
import moment from 'moment'
import { FilterMatchMode } from 'primevue/api'
import type { DataTableFilterMeta } from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

Chart.register()
const storeProfile = useProfileStore()

const toast = useToast()
const { t } = useI18n()

const selectedCompany = storeProfile.selectedCompany

const loading = ref(true)

const deliveryNotes = ref<DeliveryNote[]>([])
const customers = ref<Customer[]>([])
const employees = ref<Employee[]>([])
const vehicles = ref<Vehicle[]>([])
const places = ref<Place[]>([])
const statistics = ref<Statistics>({} as Statistics)

const deliveryNotesCounter = ref()

const isDeliveryNotesEmpty = computed(
  () => !deliveryNotes.value || (deliveryNotes.value && deliveryNotes.value.length === 0)
)

const vehiclesFilter = ref<Vehicle[]>()
const employeeFilter = ref<Employee[]>()

const getMonths = () => {
  const months: any[] = []
  const now = new Date()

  for (let i = 0; i < 24; i++) {
    const startOfMonth = new Date(now.getFullYear(), now.getMonth() - i, 1) // Primer día del mes
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() - i + 1, 0, 23, 59, 59, 999) // Último día del mes

    const monthString = startOfMonth.toLocaleString('default', { month: 'long', year: 'numeric' })
    months.push({
      label: monthString,
      startDate: dateHelper.userTimezoneToUTC(startOfMonth.toISOString()),
      endDate: dateHelper.userTimezoneToUTC(endOfMonth.toISOString())
    })
  }

  // months.reverse()

  return months
}

const monthOptions = ref(getMonths())
const monthSelected: Ref<{ startDate: string; endDate: string; label: string }> = ref<{
  startDate: string
  endDate: string
  label: string
}>({} as { startDate: string; endDate: string; label: string })

const filters = ref<DataTableFilterMeta>({
  number: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS
  },
  date: {
    value: null,
    matchMode: 'dateFilter'
  },
  'customer.name': {
    value: null,
    matchMode: FilterMatchMode.CONTAINS
  },
  employee: {
    value: null,
    matchMode: 'employeeFilter'
  },
  vehicles: {
    value: null,
    matchMode: 'vehiclesFilter'
  },
  payloads: {
    value: null,
    matchMode: 'payloadsFilter'
  },
  'origin.name': {
    value: null,
    matchMode: FilterMatchMode.CONTAINS
  },
  'destination.name': {
    value: null,
    matchMode: FilterMatchMode.CONTAINS
  }
})

onMounted(async () => {
  await loadDeliveryNotes()
  await loadDeliveryNoteStatistics()
  await loadCustomers()
  await loadVehicles()
  await loadEmployees()
  await loadPlaces()

  await drawChart()
})

async function loadDeliveryNotes() {
  loading.value = true

  if (!monthSelected.value || !monthSelected.value.startDate) {
    monthSelected.value = monthOptions.value[0]
  }

  const employeeIds = employeeFilter.value?.map((e) => e.id) || []
  const vehicleIds = vehiclesFilter.value?.map((e) => e.id) || []

  const response = await deliveryNoteService.findAll(
    monthSelected.value.startDate,
    monthSelected.value.endDate,
    employeeIds,
    vehicleIds,
    false
  )
  if (!(response instanceof ServiceError)) {
    deliveryNotes.value = response || []
  }
  loading.value = false
}

async function loadDeliveryNoteStatistics() {
  loading.value = true

  if (!monthSelected.value || !monthSelected.value.startDate) {
    monthSelected.value = monthOptions.value[0]
  }

  const employeeIds = employeeFilter.value?.map((e) => e.id) || []
  const vehicleIds = vehiclesFilter.value?.map((e) => e.id) || []

  const response = await deliveryNoteService.findStatistics(
    [ChartType.DELIVERY_NOTES_PER_DAY, ChartType.DELIVERY_NOTES_COUNT],
    monthSelected.value.startDate,
    monthSelected.value.endDate,
    employeeIds,
    vehicleIds,
    false
  )
  if (!(response instanceof ServiceError)) {
    statistics.value = response

    deliveryNotesCounter.value = statistics.value.charts.find(
      (c) => c.type === ChartType.DELIVERY_NOTES_COUNT
    )?.series[0].data[0]
  }
  loading.value = false
}

async function loadCustomers() {
  const response = await customerService.findAll()
  if (!(response instanceof ServiceError)) {
    customers.value = response || []
  }
}

async function loadVehicles() {
  const response = await vehicleService.findAll()
  if (!(response instanceof ServiceError)) {
    vehicles.value = response || []
  }
}

async function loadEmployees() {
  const response = await employeeService.findAll()
  if (!(response instanceof ServiceError)) {
    employees.value = response || []
  }
}

async function loadPlaces() {
  const response = await placeService.findAll()
  if (!(response instanceof ServiceError)) {
    places.value = response || []
  }
}

const getSignedLabel = (dn: DeliveryNote) => {
  if (dn.type === DeliveryNoteType.STANDARD) {
    return '-'
  }

  return dn.mandatorySign
    ? dn.signed === true
      ? t('common.yes')
      : t('common.no')
    : t('common.notNeeded')
}

const onDeliveryNoteView = (deliveryNote: DeliveryNote) => {
  router.push(`/deliveryNotes/${deliveryNote.id}`)
}

const onDownloadPdf = async (deliveryNote: DeliveryNote) => {
  // waitingForReport.value = true

  const response = await deliveryNoteService.downloadPdfById(deliveryNote.id)
  // waitingForReport.value = false

  if (response instanceof ServiceError) {
    toast.add({
      severity: 'error',
      summary: `${t('deliveryNotes.messages.notifications.errorDownloadDeliveryNotePdf')} ${t('messages.notifications.tryLater')}`,
      life: 3000
    })
  } else {
    downloadFile(deliveryNote, response)
  }
}

const downloadFile = (deliveryNote: DeliveryNote, response: Blob) => {
  const mimeType = 'application/pdf'

  // Crear un blob con el tipo MIME correcto
  const newBlob = new Blob([response], { type: mimeType })

  // saveAs(response, deliveryNote.number + '.pdf')

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(newBlob)
  link.download = deliveryNote.number + '.pdf' //File name Here
  link.click()
}

const onFilterChange = async () => {
  await loadDeliveryNotes()
  await loadDeliveryNoteStatistics()

  await reDrawChart()
}

let chartObject: Chart | null = null

function getData(serie: Serie): { x: number; y: number }[] {
  const data = []
  for (let i = 0; i < serie.xaxis.length; i++) {
    const label = moment(dateHelper.utcToUserTimezone(serie.xaxis[i])).valueOf()
    data.push({
      x: label,
      y: Math.trunc(serie.data[i])
    })
  }
  return data
}

const drawChart = () => {
  const container = document.getElementById('delivery-notes-chart')

  if (!container) {
    return
  }
  const canvas = container as HTMLCanvasElement
  if (!canvas) {
    return
  }

  const data = {
    // labels: statistics.value.charts[0].series[0].xaxis,
    labels: [],
    datasets: [
      {
        label: 'Albaranes por día',
        data: getData(statistics.value.charts[0].series[0]),
        backgroundColor: '#63ABFD', // no template color
        order: 1,
        showLine: true,
        tension: 0.4,
        fill: false,
        spanGaps: 1000 * 60 * 30,
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (text: any) {
              return text.parsed.y + ' albaranes'
            }
          }
        }
      }
    ]
  }

  console.log('data', data)

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItems: any[]) {
            return dateHelper.formatDate(tooltipItems[0].parsed.x)
          }
        }
      },
      legend: {
        display: true
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        suggestedMin: moment(dateHelper.utcToUserTimezone(monthSelected.value.startDate)).valueOf(),
        suggestedMax: moment(dateHelper.utcToUserTimezone(monthSelected.value.endDate)).valueOf(),
        // suggestedMin: 1725141600000,
        // suggestedMax: 1727647200000,
        type: 'time',
        time: {
          unit: 'day'
        }
      },
      y: {
        stacked: false,
        // beginAtZero: true,
        // suggestedMin: 0,
        suggestedMax: 10,
        min: 0,
        ticks: {
          precision: 0
        }
      }
    }
  }

  chartObject = new Chart(canvas, {
    type: 'bar',
    data: data,
    options: chartOptions as any
  })
}

const reDrawChart = async () => {
  if (chartObject) {
    chartObject.destroy()
  }

  drawChart()
}

const formatPayloads = (payloads: Payload[]) => {
  return (payloads as Payload[])
    .map(
      (p) => `${p.quantity.toLocaleString('es-ES')} ${t(`prices.priceUnit.${p.unit}`)} ${p.payload}`
    )
    .join('<br>')
}
</script>

<style scoped>
.summary {
  padding: 15px;
  background-color: #22303c;
  color: white;
}

.info-counter {
  margin: 20px;
  padding: 30px;
  border: 3px solid #8899ac;
  border-radius: 10px;
}

.p-card {
  padding: 5px;
}

.dashboard-1-line {
  width: 100%;
  margin: 0;
}
.c-chart__container {
  height: 30vh;
  width: 100%;
}

.delivery-note-counter-div {
  margin: 0;
  padding: 10px;
}

.delivery-note-counter-card {
  height: 100%;
}

.delivery-note-counter-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-note-counter-chart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-note-counter-chart p {
  font-size: 60px;
}

.delivery-note-per-day-chart {
  margin: 0;
  padding: 10px;
}
</style>
