import { axiosDefaultInstance } from '@/http/default'
import type { VehicleRequest } from '@/model/Vehicle'

import type Vehicle from '@/model/Vehicle'
import { useProfileStore } from '@/store/profile.module'
import type { AxiosInstance } from 'axios'
import handleError from './util/ErrorHandler'
import type { ServiceError } from './util/ServiceError'

class VehicleService {
  private axiosInstance: AxiosInstance
  private storeProfile: any

  constructor() {
    this.axiosInstance = axiosDefaultInstance
    // this.storeProfile = useProfileStore()
  }

  async findAll(): Promise<Vehicle[] | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Vehicle[]>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/vehicle`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findById(id: string): Promise<Vehicle | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Vehicle>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/vehicle/${id}`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async createVehicle(vehicle: VehicleRequest): Promise<Vehicle | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.post<Vehicle>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/vehicle`,
        vehicle
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async updateVehicle(vehicle: VehicleRequest): Promise<Vehicle | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.put<Vehicle>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/vehicle/${vehicle.id}`,
        vehicle
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deleteVehicle(id: string): Promise<Vehicle | ServiceError> {
    this.storeProfile = useProfileStore()
    
    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.delete<Vehicle>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/vehicle/${id}`
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }
}

export const vehicleService = new VehicleService()
